<template>
    <div class="body">
        <div class="concol contentbody">
             <!-- <el-button size="small" @click="router.go(-1)">back</el-button> -->
            <el-form class="form" label-width="318px" v-if="formInfo.value">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="Chinese name：">{{formInfo.value.cn_name}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="English name：">{{formInfo.value.en_name}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Gender：">
                            <!-- {{formInfo.value.gender===1?'female':'male'}} -->
                            <el-radio-group v-model="formInfo.value.gender" disabled>
                                <el-radio :label="2">female</el-radio>
                                <el-radio :label="1">male</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Highest level of education：">{{formInfo.value.highest_education_name_en}}</el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="Personal homepage or Lab homepage： ">
                            <a :href="formInfo.value.home_page" target="_blank" style="word-wrap: break-word;">{{formInfo.value.home_page}}</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="University/Institution/Company：">{{formInfo.value.company}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Position：">{{formInfo.value.position}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Research field：">{{formInfo.value.subject_name_en}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Research interest：">{{formInfo.value.research_direction}} </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Research results & Innovative projects：">
                   {{formInfo.value.research_results}}
                </el-form-item>
            </el-form>
            <div class="btn">
                <button @click="btnclick">Accept to review</button>
                <button @click="dialogVisible=true">Decline</button>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" title="Can you please let us know why you are unable to do this?" width="30%" :show-close="false">
         <el-input v-model="form.refuse_reason" :rows="5" type="textarea"  placeholder="" />
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="refuseSubmit">Confirm</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
    import { defineComponent, ref, computed, watch, reactive } from "vue";
    import { useGetters } from "@/store/use.js";
    import { useRouter, onBeforeRouteUpdate } from "vue-router";
    import store from "@/store";
    import { useI18n } from "vue-i18n";
    import nodata from "@/pages/nodata.vue";
    import { ElMessageBox, ElMessage } from 'element-plus'
    import { Ajax } from "@/utils";
    export default defineComponent({
        name: "评委守则",
        components: {
            nodata,
        },
        setup() {
            const { t, locale } = useI18n();
            const router = useRouter();
            const getters = useGetters(["language",]);
            const language = getters.language,dialogVisible=ref(false);
            const query = router.currentRoute.value.query;
            const formInfo=reactive({})
            const form=reactive({
                id:query.id,//1 已提交 2 未提交
                confirm_state:3,//2接受3拒绝
                refuse_reason:'',//拒绝理由
            })
            //获取个人信息
            const get_reviewInfo=()=>{
                Ajax.POST({url: "/api/getPersonalInformation",data:{apply_id:query.apply_id}}).then((res) => {
                        if (res.data.code === 10000) {
                            formInfo.value=res.data.data;
                        }
                    });
            }
            get_reviewInfo()
            const btnclick = () => {//确认评审提交事件
                ElMessageBox.confirm('Please confirm that you accept the invitation.', 'Tips', {
                    confirmButtonText:'Confirm',
                    cancelButtonText:'Cancel',
                    type: 'warning'
                }).then(() => {
                    form.confirm_state=2
                     Ajax.POST({url: "/api/judges/confirmState",data:form}).then((res) => {
                        if (res.data.code === 10000) {
                             ElMessage({
                                message: 'Saved successfully',
                                type: 'success',
                            })
                            setTimeout(()=>{
                                 router.push('/details?id='+query.id+'&apply_id='+query.apply_id)
                            },1000)
                        }
                    });
                }).catch(() => { return false})
            };
            const gender_sex = ref(1)
            
            const refuseSubmit=()=>{//拒绝弹窗
                if(!form.refuse_reason){
                    ElMessage({
                        message: 'Please enter the reason.',
                        type: 'warning',
                    })
                    return false;
                }else{
                     Ajax.POST({url: "/api/judges/confirmState",data:form}).then((res) => {
                        if (res.data.code === 10000) {
                             ElMessage({
                                message: 'Saved successfully',
                                type: 'success',
                            })
                            setTimeout(()=>{
                                 router.go(-1) 
                            },1000)
                        }
                    });
                }
            }
            return {
                router,
                language,
                btnclick,
                formInfo,
                gender_sex,
                dialogVisible,
                refuseSubmit,
                form,
            };
        },
    });
</script>
<style lang="scss" scoped>
    .body {
        background: #f6f6f6;
        width: 100%;
        font-family: PingFangSC-Regular, PingFang SC;

        .concol {
            padding:90px 40px 38px;
            margin-left: 6px;
            background: #fff;
            min-height: calc(100vh - 359px);
            color: #333333;
            margin: 20px auto 50px;

            :deep(.form) {
                .el-form-item{
                    margin-bottom: 40px;
                }
                .el-form-item__label {
                    font-size: 15px;
                    font-weight: 600;
                    color: #262626;
                    height: 21px;
                    line-height: 21px;
                }

                .el-form-item__content {
                    min-height: 22px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    word-wrap: break-word;
                    word-break: normal;
                    a{
                        color: #666666 ;
                        text-decoration:none
                    }
                    .el-radio{
                        height: 21px;
                        .el-radio__label{font-size: 16px;color: #333333;}
                        .el-radio__inner{
                             border-color: #DDD;
                             background: #fff;
                        }
                        .is-checked .el-radio__inner{
                            border-color: #E34C3E;
                            height: 16px;
                            width: 16px;
                        }
                        .el-radio__inner::after {
                                background-color: #E34C3E;
                                height: 8px;
                                width: 8px;
                            }
                    }
                }
            }
        }

        :deep(.btn) {
            margin: 80px 0 22px;
            text-align: center;

            span {
                font-size: 14px;
                font-weight: 400;
                color: #9B9B9B;
                line-height: 20px;
                margin-left: 10px;
            }

            button {
                font-size: 18px;
                border: 1px solid #BBBBBB;
                color: #000000;
                background: #fff;
                font-size: 18px;
                padding: 13px 39px;
                border-radius: 4px;
                // width: 150px;
                height: 50px;
                margin-top: 20px;
                cursor: pointer;
                line-height: 25px;
            }

            button:nth-child(1) {
                margin-right: 60px;
                background: #E34C3E;
                border: 1px solid #E34C3E;
                color: #fff;
            }

            //   button:hover {
            //     background: #E34C3E;
            //      color: #fff;
            //   }
        }
    }

    @media screen and (min-width: 375px) and (max-width: 750px) {
        .contentbody {
            width: 95%;

            .menucol {
                display: none;
            }

            .concol {
                max-width: 100%;
                flex: 100%
            }
        }
    }
</style>